import {DeleteLoanRequestSuccess} from './user.actions';
import {CompleteRegistration} from './authentication.actions';

export {
  NavigationActions,
  NavigationActionTypes,

  LoadAllNavigations,
  LoadNavigation,
  LoadUserNavigation,
  LoadFooterNavigation,
  LoadFooterSecondaryNavigation,
  SetNavigationError,
  SetNavigation,
  SetUserNavigation,
  SetFooterNavigation,
  SetFooterSecondaryNavigation
} from './navigation.actions';

export {
  AuthenticationActions,
  AuthenticationActionTypes,

  LogIn,
  LogOut,
  SetAuthenticationStatus,
  SetAuthenticationError,
  SetAuthenticationUserData,
  SignUpStep1,
  SignUpStep2,
  SignUpComplete,
  SignUpError,
  SignUpSuccess,
  SetSignUpIntervalData,
  RestorePassByPhone,
  RestorePassByEmail,
  RestorePassSuccess,
  RestorePassError,
  SetRestorePassState,
  ResetAuthErrors,
  SetRegistrationStep,
  CompleteRegistration,
  SetFormFieldErrors,
} from './authentication.actions';

export {
  ModalsActions,
  ModalActionTypes,

  OpenModal,
  CloseModal,
} from './modals.actions';

export {
  InfoActions,
  InfoActionTypes,

  LoadContacts,
  SetContacts,
  LoadMainPageInfo,
  SetMainPageInfo,
  LoadRefJobs,
  SetRefJobs,
  LoadRefBanks,
  SetRefBanks,
  LoadCurrentDate,
  SetCurrentDate,
  SetCalculatorAmount,
  SetCalculatorTerm,
  SetCalculatorCommission,
  SetAcceptanceText,
  LoadInfoError,
  LoadDocs,
  SetDocs,
  SetCommonErrorMessage,
  SetCommonSuccessMessage,
  SetCommonSuccessRedirect,
  SetConditions,
  LoadConditions,
  SetCalculatorLoadStatus,
  SetFormularDoc,
  LoadRefBanksSuccess,
} from './info.actions';

export {
  UserActions,
  UserActionTypes,

  LoadUserData,
  LoadUserDataSuccess,
  SetUserData,
  LoadLoanState,
  SetLoanState,
  LoadLoanSchedule,
  SetLoanSchedule,
  LoadRefinanceOptions,
  SetRefinanceOptions,
  LoadLoanCurrentInfo,
  LoadLoanCurrentInfoSuccess,
  SetLoanCurrentInfo,
  CreateLoanRequest,
  CreateLoanRefinanceRequest,
  DeleteLoanRequest,
  DeleteLoanRequestSuccess,
  LoanRequestSuccess,
  LoanRequestError,
  RefinanceRequestSuccess,
  RefinanceRequestError,
  OpenEditPopup,
  CloseUserPopup,
  SetCurrentRefinanceOptions,
  ChangeEmail,
  ChangeEmailSuccess,
  ChangeEmailError,
  ConfirmEmailChange,
  ChangePhone,
  ChangePhoneSuccess,
  ChangePhoneError,
  ConfirmPhoneChange,
  RequestVerificationCodeAgain,
  SetChangingState,
  ToggleMarketingSubscription,
  MarketingSubscriptionSuccess,
  MarketingSubscriptionError,
  ChangeUserPassword,
  CreateUserPassword,
  ChangeUserPasswordSuccess,
  LoadLoanHistory,
  SetLoanHistory,
  LoadLoanError,
  CleanUser,
  LoadPersonalConditions,
  SetPersonalConditions,
  LoadPersonalConditionsError,
  LoadPersonalRefinanceCities,
  SetPersonalRefinanceCities,
  LoadPersonalRefinanceCitiesError,
  AffiliateCompleteRegistration,
  AffiliateCompleteRegistrationSuccess,
  LoanSmsVerification,
  LoanSmsVerificationSuccess,
  LoanSmsVerificationError,
  SetLoanSmsVerificationStatus,
  RequestSmsCode,
  RequestSmsCodeSuccess,
  RequestSmsError,
  CallConfirmLoanNotification,
  LoanVerificationRequested,
  SetLoanHistoryLoadedStatus,
  SetLoanConfirmationSmsRequestedStatus,
  ChooseExtensionLoanType,
} from './user.actions';
