import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SecureHttpClientService } from 'src/app/services/secure-http-client.service';
import { Options } from 'ng5-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AppState } from '../../../../store/state';
import { Store } from '@ngrx/store';
import {
  OpenModal,
  SetCalculatorAmount,
  SetCalculatorCommission,
  SetCalculatorTerm,
  SetFormularDoc
} from '../../../../store/actions';
import { selectDateTime, selectExactConditions, selectLoanCurrentInfo, selectUser } from '../../../../store/selectors';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { addDays, debounce } from '../../../../utils';
import { ModalTypes } from '../../../../enums';
import { selectCalculatorData } from '../../../../store/selectors/info.selectors';
import { FileService } from '../../../../services/file.service';
import { BeforeRequestLoan, BeforeRequestLoanSuccess } from 'src/app/store/actions/user.actions';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalculatorComponent implements OnInit {
  private initCalculatorGuard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input()
  public transparentStyle: boolean;
  @Input()
  public showPersonalConditions: boolean;
  @Output()
  public takeLoanClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public userDiscount: number;
  public currency = 'zł';
  @Input()
  public amount = undefined;
  @Input()
  public term = 30;

  public isFirstLoan?: boolean;
  public maxTerm = 30;
  public minTerm: number;
  public commission = 323;
  public amountBorder = 3000;
  private amountThreshold;

  public isLogged: boolean = false;
  public requested: boolean;
  public excessAmount: boolean = false;

  @Input()
  public hideButton: boolean = false;

  // @TODO not sure but I think force repeat is redundant
  @Input() isForceRepeat = false;
  @Input() loanApplyState = 'next';
  @Input() maxAmount: number;
  @Input() minAmount: number;
  get apiUrl() {
    return environment.apiUrl;
  }

  public exactConditions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public amountOptions: Options = {
    floor: 100,
    ceil: 5000,
    step: 50,
    showSelectionBar: true
  };
  public termOptions: Options = {
    floor: 7,
    ceil: 30,
    step: 1,
    showSelectionBar: true
  };
  public activeLeft = true;
  public activeRight: boolean;
  public isMainPage: boolean;
  public isRegPage: boolean;
  public isNewLoanPage: boolean;

  constructor(private app: AppComponent,
    private secHttp: SecureHttpClientService,
    private router: Router,
    private authService: AuthService,
    private http: ApiService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private fileSaver: FileService,
    private changeDetector: ChangeDetectorRef) {

    this.store.select(selectCalculatorData)
      .pipe(
        tap()
      )
      .subscribe(data => {
        if (data && data.term && data.maxTerm && data.minTerm) {
          this.isFirstLoan = data.isFirstLoan;
          this.amount = data.amount ? data.amount : data.amountThreshold;
          this.term = data.term;
          this.maxTerm = data.maxTerm;
          this.minTerm = data.minTerm;
          if (this.requested) {
            this.maxAmount = data.amountThreshold;
          } else {
            this.maxAmount = data.maxAmount;
          }
          this.minAmount = data.minAmount;
          this.amountThreshold = data.amountThreshold;
          this.amountBorder = this.amountThreshold;

          if (this.amount > this.amountThreshold) {
            this.excessAmount = true;
          } else {
            this.excessAmount = false;
          }

          this.initCalculatorGuard.next(true);
        }
      });

    /*this.store.select(selectUser)
      .pipe(
        tap()
      )
      .subscribe(data => {
        if (data && data.discountPercentage) {
          this.userDiscount = data.discountPercentage;
        }
      });*/
  }

  public ConfirmationOfIncomePopup() {
    this.store.dispatch(new OpenModal(ModalTypes.CalculatorInfo));
  }


  ngOnInit() {

    this.store.select(selectLoanCurrentInfo)
      .subscribe(data => {

        if (data && data.status === 'Requested') {
          this.requested = true;

          if (this.requested) {
            this.maxAmount = data.amountThreshold;
          } else {
            this.maxAmount = data.maxAmount;
          }
        }
      });


    if (this.router.url === '/'
      || this.router.url === '/jak-pozyczyc'
      || this.router.url === '/jak-splacic-pozyczke'
      || this.router.url === '/pytania-i-odpowiedzi'
      || this.router.url === '/logowanie'
      || this.router.url === '/kontakt') {
      this.isMainPage = true;
    } else if (this.router.url.substring(0, 13) === '/registration') {
      this.isRegPage = true;
      if (this.amount > this.amountBorder) {
        this.amount = 2000;
        this.store.dispatch(new SetCalculatorAmount(this.amount));
      }
    } else if (this.router.url === '/profil/prosba-pozyczki') {
      this.isNewLoanPage = true;
    }

    if (this.loanApplyState === 'first') {
      this.amountOptions.ceil = 2000;
    }

    this.activeRight = this.loanApplyState !== 'first';
    this.activeLeft = this.loanApplyState === 'first';

    this.initCalculatorGuard.pipe(take(2)).subscribe(canInit => {
      if (canInit) {
        this.initCalculator();
      }
    });
  }

  public increaseAmountRange() {
    this.amount += this.amountOptions.step;
  }

  public decreaseAmountRange() {
    this.amount -= this.amountOptions.step;
  }

  public increaseTermRange() {
    if (this.term === this.maxTerm) {
      return;
    }
    this.term += this.termOptions.step;
  }

  public decreaseTermRange() {
    this.term -= this.termOptions.step;
  }

  public setDaysString(days: number) {
    return days > 1 ? 'dni' : 'dzień';
  }

  public togglePanels() {
    this.activeLeft = !this.activeLeft;
    this.activeRight = !this.activeRight;
    if (this.activeRight) {
      this.loanApplyState = 'next';
    } else {
      this.loanApplyState = 'first';
    }
  }

  public checkAmount() {
    if (this.amount > this.amountBorder) {
      this.activeRight = true;
      this.activeLeft = false;
      this.loanApplyState = 'next';
    } else {
      this.activeRight = false;
      this.activeLeft = true;
      this.loanApplyState = 'first';
    }
    if (this.amount > this.amountOptions.ceil) {
      this.amount = this.amountOptions.ceil;
    } else if (this.amount < this.amountOptions.floor) {
      this.amount = this.amountOptions.floor;
    }

    if (this.activeRight) {
      this.insertToDom();
    } else {
      this.removeFromDom();
    }
    debounce(this.store.dispatch(new SetCalculatorAmount(this.amount)), 150);
  }

  public checkTerm() {
    if (this.term > this.termOptions.ceil) {
      this.term = this.termOptions.ceil;
    } else if (this.term < this.termOptions.floor) {
      this.term = this.termOptions.floor;
    }
    if (this.maxTerm && this.minTerm) {
      this.termOptions.minLimit = this.minTerm;
      this.termOptions.maxLimit = this.maxTerm;
      if (this.maxTerm === this.minTerm) {
        this.term = this.minTerm;
      }
    }

    debounce(this.store.dispatch(new SetCalculatorTerm(this.term, this.maxTerm, this.minTerm)), 150);
  }

  public setPrimary() {
    this.isForceRepeat = false;
    if (this.amount > this.amountBorder) {
      this.amount = this.amountBorder;
    }
  }

  public setRepeat() {
    this.isForceRepeat = true;
  }

  public routeRegistration(e) {
    e.preventDefault();
    this.router.navigate(['/rejestracja']);
  }

  public openLoginModal() {
    this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
  }

  public openLoanInfoFile(amount, term, type, event) {
    event.preventDefault();
    this.fileSaver.openFormular(amount, term).then(resp => {
      this.store.dispatch(new SetFormularDoc({ formularDoc: resp.agreement, parent: ModalTypes.None }));
      this.store.dispatch(new OpenModal(ModalTypes.Formular));
    });
  }

  public requireNewLoan(event) {

    const requestData = {
      loanData: {
        type: "short",
        principal: this.amount,
        term: this.term
      },
      promotionCode: null,
      accessToken: this.authService.getAuthToken()
    };

    if (!this.requested) {
      this.store.dispatch(new BeforeRequestLoan({ requestData }));
    }
    else {

      let updateIncome = undefined;
      let updateExpenses = undefined;
      let confirmedBankAccount = undefined;
      let showDiscountRegulations = undefined;

      this.store.dispatch(new BeforeRequestLoanSuccess({ updateIncome, updateExpenses, confirmedBankAccount, showDiscountRegulations }));
    }


    this.takeLoanClicked.emit({
      Requested : this.requested
    });
  }

  private initCalculator() {
    this.amountOptions = {
      ...this.amountOptions,
      ceil: this.maxAmount ? this.maxAmount : this.amountThreshold,
      floor: this.minAmount ? this.minAmount : 100,
    };
    this.checkAmount();
    this.checkTerm();

    this.store.dispatch(new SetCalculatorCommission(this.commission));

    this.store.select(
      selectDateTime
    ).subscribe(dateTime => {
      if (!dateTime) {
        return;
      }
      this.store.select(selectExactConditions)
        .pipe(
          tap(),
          map(neededConditionValues => {
            if (!neededConditionValues) {
              return;
            }

            const today = new Date();

            if (this.showPersonalConditions) {
              return this.calculatePersonalValues(neededConditionValues, today);
            } else {
              return this.calculateUsualValues(neededConditionValues, today);
            }
          })
        ).subscribe(data => {
          if (!data) {
            return;
          } else {
            this.exactConditions$.next(data);
          }
        });
    });
  }

  private calculateUsualValues(neededConditionValues, today): any[] {
    const comm1 = neededConditionValues.commission;
    const comm2 = neededConditionValues.commissionSecondLoan ? neededConditionValues.commissionSecondLoan : comm1;
    const comm3 = neededConditionValues.commissionWithoutDiscount ? neededConditionValues.commissionWithoutDiscount : 0;

    const rrso1 = Number(neededConditionValues.rrso);
    const rrso2 = neededConditionValues.rrsoSecondLoan ? Number(neededConditionValues.rrsoSecondLoan) : rrso1;
    const rrso3 = Number(neededConditionValues.rrsoWithoutDiscount);

    const interest = Number(neededConditionValues.interest);
    const interestWithoutDiscount = neededConditionValues.interestWithoutDiscount;

    const total1 = this.amount + comm1 + interest;
    const total2 = this.amount + comm2 + interest;
    const total3 = this.amount + comm3 + interestWithoutDiscount;

    const maturityDate = addDays(today, this.term).toDateString();

    return [
      {
        maturityDate,
        commission: comm1.toFixed(2),
        commissionWithoutDiscount: comm3.toFixed(2) || 0,
        amount: this.amount,
        rrso: rrso1.toFixed(2),
        total: total1.toFixed(2),
        interest: interest.toFixed(2),
        interestWithoutDiscount: interestWithoutDiscount,
        rrsoWithoutDiscount: rrso3.toFixed(2),
        totalWithoutDiscount: total3.toFixed(2)
      },
      {
        maturityDate,
        commission: comm2.toFixed(2) || 0,
        commissionWithoutDiscount: comm3.toFixed(2) || 0,
        amount: this.amount,
        rrso: rrso2.toFixed(2),
        total: total2.toFixed(2),
        interest: interest.toFixed(2),
        interestWithoutDiscount: interestWithoutDiscount,
        rrsoWithoutDiscount: rrso3.toFixed(2),
        totalWithoutDiscount: total3.toFixed(2)
      }
    ];
  }

  private calculatePersonalValues(neededConditionValues, today): any[] {
    const comm1 = neededConditionValues.commission;
    const comm2 = neededConditionValues.commissionSecondLoan ? neededConditionValues.commissionSecondLoan : comm1;
    const comm3 = neededConditionValues.commissionWithoutDiscount ? neededConditionValues.commissionWithoutDiscount : 0;

    const rrso1 = Number(neededConditionValues.rrso);
    const rrso2 = neededConditionValues.rrsoSecondLoan ? Number(neededConditionValues.rrsoSecondLoan) : rrso1;
    const rrso3 = Number(neededConditionValues.rrsoWithoutDiscount);

    const interest = neededConditionValues.interest;

    const interestWithoutDiscount = neededConditionValues.interestWithoutDiscount;

    const total1 = this.amount + comm1 + interest;
    const total2 = this.amount + comm2 + interest;
    const total3 = this.amount + comm3 + interestWithoutDiscount;

    const maturityDate = addDays(today, this.term).toDateString();

    return [
      {
        maturityDate,
        commission: comm1.toFixed(2),
        commissionWithoutDiscount: comm3.toFixed(2) || 0,
        amount: this.amount,
        rrso: rrso1.toFixed(2),
        total: total1.toFixed(2),
        interestWithoutDiscount: interestWithoutDiscount,
        interest: interest,
        rrsoWithoutDiscount: rrso3.toFixed(2),
        totalWithoutDiscount: total3.toFixed(2)
      },
      {
        maturityDate,
        commission: comm2.toFixed(2) || 0,
        commissionWithoutDiscount: comm3.toFixed(2) || 0,
        amount: this.amount,
        rrso: rrso2.toFixed(2),
        total: total2.toFixed(2),
        interest: interest,
        interestWithoutDiscount: interestWithoutDiscount,
        rrsoWithoutDiscount: rrso3.toFixed(2),
        totalWithoutDiscount: total3.toFixed(2)
      }];
  }

  private insertToDom() {
    const neededSelector = document.querySelector('.ng5-slider-span.ng5-slider-pointer.ng5-slider-pointer-min');
    if (!neededSelector) {
      return;
    }
    const tooltip = document.createElement('div');

    if (this.authService.getAuthToken()) {
      this.isLogged = true;
      tooltip.className = 'slider-tooltip2';
      tooltip.innerHTML = 'Wymagane potwierdzenie<br>dochodu*';
    }
    else {
      this.isLogged = false;
      tooltip.className = 'slider-tooltip';
      tooltip.innerText = 'Kolejna pożyczka';
    }


    if (neededSelector && !neededSelector.contains(tooltip)) {
      neededSelector.append(tooltip);
    }
  }

  private removeFromDom() {
    const neededSelector = document.querySelector('.ng5-slider-span.ng5-slider-pointer.ng5-slider-pointer-min');
    if (!neededSelector) {
      return;
    }
    neededSelector.innerHTML = '';
  }
}
