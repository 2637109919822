import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/state';
import { LogOut, SetAuthenticationStatus } from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient,
              private apiService: ApiService,
              private store: Store<AppState>,
              private authService: AuthService) {
  }

  public loadDocument(id: any): Observable<Blob> {

    if (this.authService.getAuthToken()) {
    if (this.authService.getExpiredToken() &&
    (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
      this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
    } else {
      this.store.dispatch(new LogOut());
      this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
    }}

    return this.http.post(`${environment.apiUrl}eb7133f2-963a-4b01-aa45-c1fac90d03e6/GetDocument`,
      {
        documentId: id,
        accessToken: this.authService.getAuthToken()
      })
      .pipe(map(
        (resp: any) => {
          const binaryString = atob(resp.data);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          return new Blob([bytes], {type: resp.type});
        }
      ));
  }

  public openDocument(url: string): any {
    return this.http
      .get(url, {responseType: 'blob', headers: this.authService.getAuthHeaders()})
      .pipe(
        map((z) => new Blob([z], {type: 'application/pdf'}))
      ).subscribe(data => {
        if (data) {
          const fileURL = URL.createObjectURL(data);
          window.open(fileURL, '_blank');
        }
      });
  }

  public openFormular(amount, term) {

    if (this.authService.getAuthToken()) {
      if (this.authService.getExpiredToken() &&
      (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
        this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
      } else {
        this.store.dispatch(new LogOut());
        this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
      }
    }

    return this.apiService.post(`/eb7133f2-963a-4b01-aa45-c1fac90d03e6/GetLoanPreview`, {
      accessToken: this.authService.getAuthToken(),
      LoanData: {
        principal: amount,
        term,
      },
      agreement: true,
    }).toPromise();


  }
}
